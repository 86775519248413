import React, { Fragment } from "react";
import "./App.css";
import { Route } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Iframe from "react-iframe";
import KeyboardEventHandler from "react-keyboard-event-handler";

const useStyles = makeStyles((theme) => ({
  marginleft: {
    margin: 30,
    marginLeft: 200,
  },
  marginRight: {
    margin: 30,
    marginRight: 200,
  },
}));

const KeyListener = (props) => {
  const { advance, recede } = props;
  return (
    <div>
      <KeyboardEventHandler
        handleKeys={["left", "right"]}
        handleFocusableElements={true}
        isExclusive={true}
        onKeyEvent={(key, e) => {
          if (key === "left") {
            recede();
          } else if (key === "right") {
            advance();
          }
          console.log(`do something upon keydown event of ${key}`);
        }}
      />
    </div>
  );
};

export const Buttons = ({ match }) => {
  const { token, channel } = match.params;
  var myurl = ""
  if(token==="advanced") {
    myurl = "https://advanced.vmixcall.com/"
  }
  else if(token==="no") {
    myurl = ""
  }
  else {
    myurl = "https://vmix.at/"+token
  }
  const channelsuffix = channel ? `?channel=${parseInt(channel, 10)}` : "";
  const xhr = (control) => {
    const url = `/backend/${control}.php${channelsuffix}`;
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.text())
      .then((html) => console.log(html));
  };
  const advance = () => xhr("next");
  const recede = () => xhr("back");
  const classes = useStyles();
  if(token==="no") {
    return (
      <div style={{
        width: "80%",
        position: 'absolute', left: '50%', top: '50%',
        transform: 'translate(-50%, -50%)'
        }}>
          <b style={{fontSize: 50}}>Powerpoint control</b>
        <KeyListener advance={advance} recede={recede} />
        <Button
          className={classes.marginRight}
          onClick={recede}
          variant="contained"
          color="primary"
          style={{ width: "300px", height: "200px", marginLeft: "0px", marginRight: "10px", backgroundColor: "red"}}
        >
          Back
        </Button>
        <Button
          className={classes.marginleft}
          onClick={advance}
          variant="contained"
          color="primary"
          style={{ width: "300px", height: "200px", marginLeft: "0px", marginRight: "10px", backgroundColor: "green"}}
        >
          Next
        </Button>
      </div>
    );
  }
  else {
    return (
      <Fragment>
        <KeyListener advance={advance} recede={recede} />
        <Iframe
          allow="camera *; microphone *;"
          url={`${myurl}`}
          width="100%"
          id="myId"
          height="750px"
        >Powerpoint Remote Control</Iframe>
        <Button
          className={classes.marginRight}
          onClick={recede}
          variant="contained"
          color="primary"
          size="large"
          style={{backgroundColor: "red"}}
        >
          Back
        </Button>
        <Button
          className={classes.marginleft}
          onClick={advance}
          variant="contained"
          color="primary"
          size="large"
          style={{backgroundColor: "green"}}
        >
          Next
        </Button>
      </Fragment>
    );
  }
};

function App() {
  return (
    <div className="App">
      <Route path="/vmixcall/:token?/:channel?" component={Buttons} />
    </div>
  );
}

export default App;
